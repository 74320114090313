import React, { Component } from 'react';
import logo from './img/studio_buergisser.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
        <img src={logo} className="App-logo" alt="Studio Bürgisser" />
        <h1>Studio Bürgisser.</h1>

        <p className="footer">
          <span className="footerItem">Dani Bürgisser</span>
          <span className="spacer">•</span>
          <span className="footerItem">Creative Services</span>
          <span className="spacer">•</span>
          <span className="footerItem"><a href="tel:00417642955">+41 76 429 29 55</a></span>
          <span className="spacer">•</span>
          <span className="footerItem emailBig"><a href="mailto:hello@studiobuergisser.com">hello@studiobuergisser.com</a></span>
          <span className="footerItem emailSmall"><a href="mailto:hello@studiobuergisser.com">Mail me</a></span>
        </p>

        <p className="arrows">
          <div className="arrow one" style={{opacity: 0.25}}></div>
          <div className="arrow two" style={{opacity: 0.45}}></div>
          <div className="arrow three" style={{opacity: 0.65}}></div>
          <div className="arrow four"></div>
        </p>
        <p className="cta">
          <a href="dbuergisser_portfolio2018.pdf" target="_blank">PORTFOLIO</a>
        </p>
        <p className="overline">Download (66MB)</p>
        </div>
      </div>
    );
  }
}

export default App;
